import Home from "../pages/Home";

export interface Page {
  title: string;
  path: string;
  render: () => JSX.Element;
}

const pages: Page[] = [
  Home,
];

export default pages;