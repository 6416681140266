import { useEffect, useState } from "react";
import { Page } from "../utils/Pages";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { PollEntry, PollVote } from "../utils/PollUtils";
import { useSearchParams } from "react-router-dom";

function Render(): JSX.Element {
  const [urlParams, setUrlParams] = useSearchParams();

  const {sendMessage, lastMessage, readyState} = useWebSocket('wss://irc-ws.chat.twitch.tv:443');

  const [isPollStarted, setPollStarted] = useState<boolean>(false);
  const [currentPollEntry, setCurrentPollEntry] = useState<PollEntry>();
  const [pollQuestion, setPollQuestion] = useState<string>(urlParams.get("question") ?? '');
  const [pollEntries, setPollEntries] = useState<PollEntry[]>([]);
  const [pollVotes, setPollVotes] = useState<PollVote[]>([]);
  const [pollTimeOption, setPollTimeOption] = useState<number>(Number.parseInt(urlParams.get("time") ?? '60'));
  const [pollCountdown, setPollCountdown] = useState<number>(0);
  const [pollBarColor, setPollBarColor] = useState<string>(urlParams.get("barcolor") || '#a970ff');
  const [pollWinningEntry, setPollWinningEntry] = useState<PollEntry | undefined>();
  const [streamersList, setStreamersList] = useState<string>(urlParams.get("streamers") ?? '');

  useEffect(() => {
    const urlEntries = urlParams.get("entries")?.split(',');

    const actualEntries: PollEntry[] = [];
    urlEntries?.forEach(entry => actualEntries.push({text: entry, votes: 0}));

    setPollEntries(actualEntries);
  }, []);

  useEffect(() => {
    if (readyState != ReadyState.OPEN) return;

    fetch("https://twitchauthkey.bot54249.workers.dev/")
    .then(response => response.text())
    .then(data => {
      sendMessage(`PASS oauth:${data}`);
      sendMessage(`NICK swagrobobot`);

      if (urlParams.get("autostart") === 'yes') startPoll();
    })
  }, [readyState]);

  useEffect(() => {
    if (!isPollStarted) return;
    if (!lastMessage) return;
    if (!(lastMessage.data as string).includes("PRIVMSG")) return;

    const username = (lastMessage.data as string).split("PRIVMSG")[0].split("!")[0].replace(':', '');
    if (pollVotes.filter(vote => vote.username === username).length !== 0) return;

    const vote = Number.parseInt((lastMessage.data as string).split("PRIVMSG")[1].split(":")[1]);
    if (vote <= 0 || vote > pollEntries.length) return;
    if (Number.isNaN(vote)) return;

    if (pollEntries.at(vote-1)) pollEntries.at(vote-1)!.votes += 1;

    setPollVotes(prev => prev.concat({username, vote}));
  }, [lastMessage]);

  useEffect(() => {
    setTimeout(() => {
      if (!isPollStarted) return;

      setPollCountdown(prev => prev-1);

      const winningEntry = [...pollEntries].sort((a, b) => b.votes - a.votes)[0];
      if (winningEntry.votes != 0) setPollWinningEntry(winningEntry);
    }, 10 * pollTimeOption);

    if (pollCountdown === 0) setPollStarted(false);
  }, [isPollStarted, pollCountdown]);

  const addEntry = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (currentPollEntry && currentPollEntry.text !== '') setPollEntries((prev) => prev.concat(currentPollEntry));
    setCurrentPollEntry({text: '', votes: 0});
  }

  const removeEntry = (index: number) => {
    const newPollEntries = [...pollEntries];
    newPollEntries.splice(index, 1);
    setPollEntries(newPollEntries);
  }

  const startPoll = () => {
    const joinMessage = 'JOIN ' + streamersList.replace(' ', '').split(',').map(streamer => '#'+streamer).join(',');
    sendMessage(joinMessage);

    setPollVotes([]);
    pollEntries.forEach(entry => entry.votes = 0);
    setPollWinningEntry(undefined);
    setPollStarted(true);
    setPollCountdown(100);
  }

  const endPoll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPollCountdown(0);
    setPollStarted(false);
  }

  const clearPoll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPollEntries([]);
    setPollTimeOption(60);
    setPollVotes([]);
    setStreamersList('');
    setPollQuestion('');
    setPollCountdown(0);
    setPollBarColor('#a970ff');
    setPollWinningEntry(undefined);
    setPollStarted(false);
  }

  return (
    <div>
      <div className='d-flex gap-3 vh-100'>
        <div className='border bg-dark rounded w-100 p-3 my-auto'>
          <p><b>Poll for</b> {streamersList.replace(',', ', ')}</p>

          <hr />

          <div className="progress mb-2" role="progressbar">
            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${pollCountdown}%`, backgroundColor: pollBarColor}}></div>
          </div>

          <p className='form-control border-0 shadow-none fw-bold fs-3 p-0 mb-3'>{pollQuestion}</p>

          <ul className='list-group mt-3'>
            {
              pollEntries.map((entry, index) => {
                return entry === pollWinningEntry ?
                <div className='list-group-item d-flex' style={{color: '#fac125'}}>
                  <div className='w-100'> {index+1} - {entry.text} ({entry.votes}) <i className='bi bi-award-fill'></i> </div>
                  { !isPollStarted && <button className='btn btn-outline-secondary btn-sm py-0' onClick={() => removeEntry(index)}><i className='bi bi-trash-fill'></i></button> }
                </div>
                :
                <div className='list-group-item d-flex'>
                  <div className='w-100'> {index+1} - {entry.text} ({entry.votes}) </div>
                  { !isPollStarted && <button className='btn btn-outline-secondary btn-sm py-0' onClick={() => removeEntry(index)}><i className='bi bi-trash-fill'></i></button> }
                </div>
              })
            }
          </ul>
        </div>

        <div className='w-100 my-auto'>
          <div className='border bg-dark rounded p-3'>
            <h4>Options</h4>
            <hr/>

            <div className="btn-group w-100">
              {
                !isPollStarted ?
                <button className='btn btn-success w-100' onClick={startPoll} disabled={pollEntries.length < 2}>Start Poll</button> :
                <button className='btn btn-danger w-100' onClick={endPoll}>End Poll</button>
              }

              <button className='btn btn-secondary w-100' onClick={clearPoll}>Clear Poll</button>
            </div>

            <hr/>

            <div className="input-group mb-2">
              <span className="input-group-text" id="basic-addon1">Question</span>
              <input type='text' className="form-control" placeholder='Question...' value={pollQuestion} onChange={e => setPollQuestion(e.target.value)} />
            </div>

            <div className="input-group mb-2">
              <span className="input-group-text" id="basic-addon1">Streamers</span>
              <input type="text" className="form-control" placeholder="streamer1, streamer2, ..." value={streamersList} onChange={(e) => setStreamersList(e.target.value)} />
            </div>

            <div className="d-flex gap-2">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">Time (seconds)</span>
                <input type="number" min={0} className="form-control" value={pollTimeOption} onChange={(e) => setPollTimeOption(Number.parseInt(e.target.value))} />
              </div>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">Bar color</span>
                <input type="text" className="form-control" placeholder="#000000, purple, ..." style={{color: pollBarColor}} value={pollBarColor} onChange={(e) => setPollBarColor(e.target.value)} />
              </div>
            </div>

            <hr/>

            <form className='row g-2' onSubmit={addEntry}>
              <div className='col'>
                <input className='form-control' type='text' placeholder='Add Poll Entry...' value={currentPollEntry?.text} onChange={(e) => setCurrentPollEntry({text: e.target.value, votes: 0})} />
              </div>
              <div className='col-auto'>
                <input className='btn btn-success' type='submit' value='Add' disabled={isPollStarted} />
              </div>
            </form>

            <hr />

            <textarea rows={2} className='form-control' readOnly value={`https://twitchmultipoll.pages.dev?autostart=yes${pollBarColor !== "" ? "&barcolor=" + pollBarColor.replace("#", "%23") : ""}${pollQuestion !== "" ? "&question=" + pollQuestion.replace("?", "%3F") : ""}${!Number.isNaN(pollTimeOption) ? "&time=" + pollTimeOption : ""}${streamersList !== "" ? "&streamers=" + streamersList.replace(" ", ""): ""}${pollEntries.length !== 0 ? "&entries=" + pollEntries.map(e => e.text).join(",") : ""}`}></textarea>
          </div>

          <div className='border bg-dark rounded p-3 mt-3'>
            <h4>Credits</h4>
            <hr />
            <p>
              Made with ❤ by <a href='https://www.twitch.tv/pinguiotv' target='_blank'>Pingu-Io</a>, inspired from <a href='https://www.twitch.tv/enkk' target='_blank'>Enkk</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const Home: Page = {
  title: "Home",
  path: "/",
  render: Render,
}

export default Home;